import * as React from "react"
import ShowClaimedNftStatic from "../../components/show-claimed-nft-static";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const PopulusArtwork = ( props ) => {

  const address = props.params.address;

  return (
      <Layout>
        <section className='section-title white collection artwork'>
          <Seo title="Playground - Populus Gallery View" />
          <div className='container clearfix flex'>
              <ShowClaimedNftStatic address={address}/>
          </div>
        </section>
      </Layout>
  );
}

export default PopulusArtwork
