import * as React from "react"
import {useEffect, useState} from "react";
import {useConnection, useWallet} from '@solana/wallet-adapter-react'

import {Link} from "gatsby";
import loadingGif from "../images/loading.gif";

const ShowClaimedNftStatic: React.FC = (props) => {

    const {connection} = useConnection();
    const {wallet, publicKey, signTransaction} = useWallet();
    const [theNft, setTheNft] = useState({});
    const [theAttributes, setTheAttributes] = useState({});
    const [toRender, setToRender] = useState(false);

    const cdnPrefix = "https://prod-image-cdn.tensor.trade/images/slug=playground-populus/400x400/freeze=false/";
    const address = props.address;

    const getTheNft = React.useCallback(async () => {

        fetch("/populus/populus.json")
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Assuming 'data' is an array of items
                const foundItem = data.find(item => item.onchainId === address);
                if (foundItem) {
                    // Set the artwork items to state
                    setTheNft(foundItem);
                    setTheAttributes(JSON.parse(foundItem.attributes));
                    console.log(foundItem);
                } else {
                    console.log('Artwork not found');
                }
            })
            .catch(error => {
                console.error('There has been a problem with fetching the data:', error);
            });

    }, [wallet, connection]);


    useEffect(() => {
        getTheNft();
    }, []);

    useEffect(() => {
        if (theNft.onchainId) {
            setToRender(true);
        }
    }, [theNft]);


    const RenderArtwork = () => (
        <>
            <div className='col40 left artwork-info'>
                <div className='clearfix tags'>
                    <Link to='/populus/' className='button black left'>back to gallery</Link>
                </div>
                <h1>{theNft.name}</h1>
                <p className='big'>Populus imagines a future where humanity and technology have merged at the physical
                    level. Born out of the question ‘What if evolution and annihilation are the same thing?’, Populus
                    envisions a world where humanity has broken with its biological origins and found new ways to
                    augment and amplify its material capacities. In this ‘new order’, much as in our current one, access
                    to privilege and power is unequal, with unsettling ramifications for society as a whole. As some
                    individuals are elevated to godlike status by their technological hybridization, others are
                    condemned to madness, marginalisation, or servitude.</p>
                <p className='big'>Offering a strikingly fresh spin on the profile pic (or PfP), Populus consists of 150
                    full-length character designs ranging from the ethereally beautiful to the nightmarish. Each
                    character is named with a fragment from Victorian or Edwardian poetry, and assigned traits of
                    varying rarity – including the social caste to which the character belongs. The result is a diverse
                    and startling collection of portraits of impossible beings from an increasingly possible future -
                    fascinating, sometimes alluring, often terrifying, and utterly unique.</p>
                <div className='details'>
                    <a className='button transparent'>AI long form</a>
                    <a className='button transparent'>Curated</a>
                    <a className='button transparent'>Edition of 150</a>
                </div>
                <div className='features'>
                    <h4>Features</h4>
                    <ul>
                        {theAttributes && (
                            theAttributes.map((attribute, index) => (
                                <li key={index}>
                                    <span>{attribute.trait_type}:</span>
                                    <span>{attribute.value}</span>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            </div>
            <div className='col60 left image populus'>
                <img src={cdnPrefix + theNft.imageUri}/><br/>
                <Link to={theNft.imageUri} target='_blank' className='button transparent expand'>view high
                    resolution</Link>
            </div>
        </>
    );

    return (
        <>
            {
                toRender
                    ? (<RenderArtwork/>)
                    : (<><img alt="Loading" className="loading" src={loadingGif}/></>)
            }
        </>
    );
};

export default ShowClaimedNftStatic;